import { connect } from 'react-redux';
import { AnyAction, Dispatch } from 'redux';

import StaticHomePage from 'Component/StaticHomePage';
// import CmsPage from 'Route/CmsPage';
import {
    HomePageContainer as SourceHomePageContainer,
    InstallPrompt as SourceInstallPrompt,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
} from 'SourceRoute/HomePage/HomePage.container';
import { ReactElement } from 'Type/Common.type';
import { RootState } from 'Util/Store/Store.type';

import './HomePage.override.style.scss';

// TODO: implement InstallPrompt
export const InstallPrompt = SourceInstallPrompt;

/** @namespace Pwa/Route/HomePage/Container/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

/** @namespace Pwa/Route/HomePage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

/** @namespace Pwa/Route/HomePage/Container */
export class HomePageContainer extends SourceHomePageContainer {
    render(): ReactElement {
        const { isInstallPromptAvailable } = this.state;

        // <CmsPage { ...this.containerProps() } />
        return (
            <div block="HomePage" mods={ { mobile: !!isInstallPromptAvailable } }>
                { this.renderInstallPrompt() }
                <StaticHomePage />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer);
