/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/forbid-elements */
/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import CmsBlock from '@scandipwa/scandipwa/src/component/CmsBlock';
import { PureComponent, ReactElement } from 'react';

import { StaticHomePageComponentProps } from './StaticHomePage.type';

import './StaticHomePage.style';

/** @namespace Pwa/Component/StaticHomePage/Component */
export class StaticHomePageComponent extends PureComponent<StaticHomePageComponentProps> {
    baseUrl = '/search/%20?customFilters=family:';

    getCurrentEnv() {
        const baseUrl = window.location.href;

        if (baseUrl.includes('b2btest.motorparts')) {
            return 'stg';
        }

        return 'prod';
    }

    getUrlToSearch(codeStg: string, codeProd: string, currentEnv: string, disableBaseUrl:boolean = false) {
        const code = (currentEnv === 'stg') ? codeStg : codeProd;

        return disableBaseUrl ? `${code}` : `${this.baseUrl}${code}`;
    }

    blockHomepageTop(): ReactElement {
        return (
            <div block="StaticHomePage" elem="ContainerBTop">
                <div block="StaticHomePage" elem="LeftSection">
                    <img src="/media/wysiwyg/moto.png" alt="moto" />
                    <div block="StaticHomePage" elem="Overlay">
                        <img src="/media/.renditions/wysiwyg/flag.png" alt="Brand Logo" />
                        <h2>{ __('BlockHpTop.title') }</h2>
                        <p>{ __('BlockHpTop.desc') }</p>
                        <a href="/search/%20" rel="noopener noreferrer">
                            <button block="StaticHomePage" elem="Button">{ __('BlockHpTop.showProduct') }</button>
                        </a>
                    </div>
                </div>
                <div block="StaticHomePage" elem="RightSection">
                    <div>
                        <h3>{ __('BlockHpTop.ask') }</h3>
                        <p>{ __('BlockHpTop.askDesc') }</p>
                        <a href="/servizio-clienti.html">{ __('BlockHpTop.contactUs') }</a>
                    </div>
                    <div block="StaticHomePage" elem="RightSecond">
                        <h3>{ __('BlockHpTop.titleTwo') }</h3>
                        <p>{ __('BlockHpTop.askSignUp') }</p>
                        <a href="#">{ __('BlockHpTop.signUp') }</a>
                    </div>
                </div>
            </div>
        );
    }

    blockHomepageMarche(): ReactElement {
        const currentEnv = this.getCurrentEnv();

        const urltopPerf = this.getUrlToSearch('794245', '77', currentEnv);
        const urlracingParts = this.getUrlToSearch('794206', '38', currentEnv);
        const urlTprfactory = this.getUrlToSearch('794248', '80', currentEnv);
        const urlMph = this.getUrlToSearch('794221', '53', currentEnv);
        const urlLeovince = this.getUrlToSearch('794266', '98', currentEnv);
        const urlTrw = this.getUrlToSearch('794251', '83', currentEnv);
        const urlSachs = this.getUrlToSearch('794236', '68', currentEnv);
        const urlNgk = this.getUrlToSearch('794224', '56', currentEnv);
        const urlReckless = this.getUrlToSearch('794233', '65', currentEnv);
        const urlNtn = this.getUrlToSearch('794242', '/search/%20?customFilters=sub_group:3815', currentEnv, true);
        const urlOsram = this.getUrlToSearch('794212', '/search/LAMPADINE%20OSRAM', currentEnv, true);
        const urlToplight = this.getUrlToSearch('794212', '/search/LAMPADINE%20TOPLIGHT', currentEnv, true);
        const urlXforte = this.getUrlToSearch('794230', '/search/PRODOTTI%20PER%20PASSIONE%20XFORTE', currentEnv, true);
        const urlHiflofiltro = this.getUrlToSearch('794263', 'search/HIFLO', currentEnv, true);

        return (
            <>
                <div block="StaticHomePage" elem="ContainerMarche">
                    <div block="StaticHomePage" elem="ContainerRight">
                        <div block="StaticHomePage" elem="TextBox">
                            <h1>{ __('BlockHpMarche.title') }</h1>
                            <p>{ __('BlockHpMarche.desc') }</p>
                        </div>
                    </div>
                    <div block="StaticHomePage" elem="ImageGrid">
                        <a href={ urltopPerf } rel="noopener noreferrer">
                            <img src="/media/.renditions/wysiwyg/topperformances.png" alt="" />
                        </a>
                        <a href={ urlracingParts } rel="noopener noreferrer">
                            <img src="/media/.renditions/wysiwyg/racingparts.png" alt="racingparts" />
                        </a>
                        <a href={ urlTprfactory } rel="noopener noreferrer">
                            <img src="/media/.renditions/wysiwyg/tprfactory.png" alt="tprfactory" />
                        </a>
                        <a href={ urlMph } rel="noopener noreferrer">
                            <img src="/media/.renditions/wysiwyg/mph.png" alt="mph" />
                        </a>
                    </div>
                </div>
                <div block="StaticHomePage" elem="ImageList">
                    <a href={ urlLeovince } rel="noopener noreferrer">
                        <img src="/media/.renditions/wysiwyg/leovince.png" alt="leovince" />
                    </a>
                    <a href={ urlHiflofiltro } rel="noopener noreferrer">
                        <img src="/media/.renditions/wysiwyg/hiflofiltro.png" alt="hiflofiltro" />
                    </a>
                    <a href={ urlTrw } rel="noopener noreferrer">
                        <img src="/media/.renditions/wysiwyg/trw.png" alt="trw" />
                    </a>
                    <a href={ urlSachs } rel="noopener noreferrer">
                        <img src="/media/.renditions/wysiwyg/sachs.png" alt="sachs" />
                    </a>
                    <a href={ urlOsram } rel="noopener noreferrer">
                        <img src="/media/.renditions/wysiwyg/osram.png" alt="osram" />
                    </a>
                    <a href={ urlNgk } rel="noopener noreferrer">
                        <img src="/media/.renditions/wysiwyg/ngk.png" alt="toplight" />
                    </a>
                    <a href={ urlToplight } rel="noopener noreferrer">
                        <img src="/media/.renditions/wysiwyg/toplight.png" alt="toplight" />
                    </a>
                    <a href={ urlReckless } rel="noopener noreferrer">
                        <img src="/media/.renditions/wysiwyg/reckless.png" alt="reckless" />
                    </a>
                    <a href={ urlNtn } rel="noopener noreferrer">
                        <img src="/media/.renditions/wysiwyg/ntn.png" alt="ntn" />
                    </a>
                    <a href={ urlXforte } rel="noopener noreferrer">
                        <img src="/media/.renditions/wysiwyg/xforte.png" alt="xforte" />
                    </a>
                </div>
            </>
        );
    }

    blockHomepagePromo(): ReactElement {
        return (
            <section block="StaticHomePage" elem="PromoSection">
                <img src="/media/.renditions/wysiwyg/moto2.png" alt="" />
                <div block="StaticHomePage" elem="PromoText">
                    <div block="StaticHomePage" elem="PromoLabel">{ __('BlockHpPromo.subTitle') }</div>
                    <h2>{ __('BlockHpPromo.title') }</h2>
                    <p>{ __('BlockHpPromo.desc') }</p>
                </div>
            </section>
        );
    }

    blockHomepageOfferte(): ReactElement {
        return (
            <div block="StaticHomePage" elem="Container">
                <div block="StaticHomePage" elem="Card">
                    <img src="/media/.renditions/wysiwyg/homepage-promo1.png" alt="" />
                    <div block="StaticHomePage" elem="CardText">
                        <img src="/media/.renditions/wysiwyg/flag.png" alt="" />
                        <h3>{ __('BlockHpOfferte.titleBoxOne') }</h3>
                        <a href="/search/%20?customFilters=family:794221" rel="noopener noreferrer">
                            <button>{ __('BlockHpTop.showProduct') }</button>
                        </a>
                    </div>
                </div>
                <div block="StaticHomePage" elem="Card">
                    <img src="/media/.renditions/wysiwyg/homepage-promo2.png" alt="" />
                    <div block="StaticHomePage" elem="CardText">
                        <img src="/media/.renditions/wysiwyg/flag.png" alt="" />
                        <p>{ __('BlockHpOfferte.subTitleBoxTwo') }</p>
                        <h3>{ __('BlockHpOfferte.titleBoxTwo') }</h3>
                        <a href="/offerte-settimana.html" rel="noopener noreferrer">
                            <button>{ __('BlockHpTop.showProduct') }</button>
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    blockHomepageProductPromo(): ReactElement {
        return (
            <div block="StaticHomePage" elem="ContainerSpecialPromo">
                <div block="StaticHomePage" elem="PromoBanner">
                    <h1>{ __('BlockHpProductPromo.title') }</h1>
                    <p>{ __('BlockHpProductPromo.desc') }</p>
                    <a
                      href="/search/%20?customFilters=family:794221"
                      rel="noopener noreferrer"
                      block="StaticHomePage"
                      elem="Button"
                    >
                        { __('BlockHpTop.showProduct') }
                    </a>
                        <div block="StaticHomePage" elem="Swoosh" />
                </div>
                <div block="StaticHomePage" elem="CarouselProduct">
                    <CmsBlock identifier="homepage-widget-products" />
                </div>
            </div>
        );
    }

    render() {
        return (
            <div block="StaticHomePage">
                <div block="CmsPage-Wrapper CmsPage-Wrapper_page_width_default">
                    { this.blockHomepageTop() }
                    { this.blockHomepageMarche() }
                    <CmsBlock identifier="homepage-prodotti-primo-piano" />
                    { this.blockHomepagePromo() }
                    { this.blockHomepageProductPromo() }
                    { this.blockHomepageOfferte() }
                </div>
            </div>
        );
    }
}

export default StaticHomePageComponent;
